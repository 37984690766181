@font-face {
  font-family: "Digital-7 Mono";
  src: url("/assets/fonts/digital-7-mono.ttf") format("truetype");
}

.hot-color {
  filter: saturate(110%);
}

.cold-color {
  filter: saturate(90%);
}
